<template>
  <div>
    <article class="page order">
      <div class="content-con">
        <h2 class="page-tit"><em>订单列表</em><span>你的历史订单</span></h2>
        <section class="order-list">
          <table class="tab-basic">
            <!-- Table head -->
            <thead>
              <tr>
                <th>订单编号</th>
                <th class="text-center">付款状态</th>
                <th class="text-center">物流状态</th>
                <th>总金额</th>
              </tr>
            </thead>
            <!-- /.Table head -->
            <!-- Table body -->
            <tbody>
              <tr v-for="(item, key) in orderData" :key="key">
                <td>
                  <a :href="'OrderDetail?orderId=' + item.OrderId">{{
                    item.OrderId
                  }}</a>
                </td>
                <td class="td-status">
                  <span class="status-solved" v-if="item.PayStatus == 0"
                    >未付款</span
                  >
                  /
                  <a
                    :href="item.PaymentInfo"
                    target="_blank"
                    v-if="item.PayStatus == 0"
                    >二维码</a
                  >
                  <span class="status-solved" v-if="item.PayStatus == 1"
                    >已付款</span
                  >
                </td>
                <td class="td-status">
                  <span class="status-solved" v-if="item.ShipStatus == 1"
                    >已出货</span
                  >
                  <span class="status-solved" v-if="item.ShipStatus == 0"
                    >尚未出货</span
                  >
                </td>
                <td class="">
                  <span class="status-solved">{{ item.TotalPrice }}</span>
                </td>
              </tr>
            </tbody>
            <!-- /.Table body -->
          </table>
        </section>
      </div>
    </article>
  </div>
</template>

<script>
import { GetOrderList } from "@/service/api";
import router from "../router";

export default {
  name: "Order",
  data() {
    return {
      phoneNo: "",
      orderData: [],
      PaymentType: ["CC", "ATM", "MMK", "CGP", "USDT", "BTC", "ETH"],
      OrderStatus: ["处理中", "已完成", "已退款", "已建立"],
      PayStatus: ["未付款", "已付款"],
      ShipStatus: ["未出货", "已出货"],
    };
  },
  mounted: function () {
    this.$parent.SetWindowTitle("订单查询结果");
    GetOrderList(this.$route.query.phoneNo)
      .then((resp) => {
        this.orderData = resp.data;
      })
      .catch((data) => {
        if (data.status === 401) alert("Error: token is not found;");
        if (data.status === 403) alert("Error: authentication failed;");
      });
    this.phoneNo = this.$route.query.phoneNo;
    $(function () {
      //banner 設定尺寸
      $(".banner-cover").addClass("_lvThr");
      $("#ban1").css("background-image", "url(assets/img/banner_lvThr1.jpg)");
    });
  },
  methods: {
    GetImageUrl: function (imgId, size) {
      var d = new Date();
      var n = d.getTime();
      return (
        "https://n2imageservice.azurewebsites.net/api/images/shopmall/" +
        imgId +
        "/" +
        size +
        "/0?" +
        n
      );
    },
    formatDate: function (value) {
      if (value == null) return "";
      return value.replace("T", " ");
    },
    OpenFancyBox(url) {
      $.fancybox.open({ src: url, type: "iframe" });
      $(document).on("afterClose.fb", function (e, instance, slide) {
        location.replace(location);
      });
    },
    ShipVender(str) {
      return str.split("-")[0];
    },
    OpenLogistics(orderId, phoneNo) {
      return (
        "window.open('PopUp-Logistics?orderId=" +
        orderId +
        "&phoneNo=" +
        phoneNo +
        "','LogisticsInfo',config='height=400,width=550,toolbar=no')"
      );
    },
    OpenRecipient(orderId, phoneNo) {
      return (
        "window.open('PopUp-Recipient?orderId=" +
        orderId +
        "&phoneNo=" +
        phoneNo +
        "','RecipientInfo',config='height=400,width=550,toolbar=no')"
      );
    },
    CollapseHref(id) {
      return "#" + id;
    },
  },
};
</script>
